<template>
  <div class="container">
    <div class="poster-box">
      <div class="poster">
        <van-image class="poster-img" :src="posterUrl"></van-image>
      </div>
    </div>
    <div class="save-box">
      <div class="save" @click="savePoster">保存本地</div>
    </div>
  </div>
</template>
<script>
// import CallApp from "callapp-lib";
export default {
  name: "Poster",
  data() {
    return {
      posterUrl: "",
    };
  },
  created() {
    document.title = "";
    // this.posterUrl = decodeURIComponent(this.$route.query.posterUrl);
    this.posterUrl = this.$route.query.posterUrl;
    console.log("posterUrl结果===>", this.posterUrl);
  },
  // 在使用到的页面 添加如下代码
  mounted() {
    // 监听页面状态
  },
  computed: {},
  methods: {
    async savePoster() {
      savePictureToLocalAlbum.postMessage(
        JSON.stringify({
          posterUrl: this.posterUrl,
        })
      );

      console.log("海报保存到本地===>");
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  padding: 20px;

  width: 375px;
  height: 812px;
  background: #f9f9f9;
  .poster-box {
    width: 100%;
    height: 536px;
    .poster {
      width: 100%;
      height: 100%;
      .poster-img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .save-box {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    .save {
      width: 250px;
      height: 40px;
      background: #f67f58;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #fefefe;
    }
  }
}
</style>
